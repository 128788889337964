import React, { Fragment } from "react"
import FormInput from "../Elements/Form/FormInput"
import FormCheckbox from "../Elements/Form/FormCheckbox"

const contactPersonBlurb =
  "Assigned authorized recipient to receive the medicine"

const PatientDetails = ({ values = {}, onChange }) => {
  return (
    <Fragment>
      <FormInput
        name="firstName"
        label="First Name"
        placeholder="Juan"
        isRequired
      />
      <FormInput
        name="lastName"
        label="Last Name"
        placeholder="Dela Cruz Jr."
        isRequired
      />
      <FormInput
        name="emailAddress"
        label="Email Address"
        placeholder="jdelacruz@mail.com"
        isRequired
      />
      <FormInput
        name="mobileNumber"
        label="Mobile Number"
        placeholder="09123456789"
        isRequired
        maxLength={11}
        isNumeric
      />
      <FormCheckbox
        name="contactPerson"
        values={values?.contactPerson}
        options={[contactPersonBlurb]}
        isRequired
      />
      {values?.contactPerson.includes(contactPersonBlurb) && (
        <section>
          <FormInput
            name="authorizedPerson.fullName"
            label="Contact Person"
            isRequired
            placeholder="Juan Dela Cruz Jr."
          />
          <FormInput
            name="authorizedPerson.contactNumber"
            label="Mobile Number of Contact Person"
            placeholder="09123456789"
            isRequired
            maxLength={15}
            isNumeric
          />
        </section>
      )}
    </Fragment>
  )
}

export default PatientDetails
